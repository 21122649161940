import { queries } from "#/lib/atoms/queries";
import { useAtom } from "jotai";
import { useMemo } from "react";

export const useUser = () => {
  const [{ data }] = useAtom(queries.user);
  const [{ data: members }] = useAtom(queries.members);

  const member = useMemo(() => {
    return members?.members?.find(
      (member) => member?.user?.email === data?.email,
    );
  }, [members, data]);

  return { data, role: member?.role };
};
